var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'input',
    { 'has-error': _vm.error },
    { 'has-prefix': _vm.prefix },
    { [`input--${_vm.size}`]: !!_vm.size },
    { 'is-honey-pot': _vm.isHoneyPot },
    { 'is-readonly': _vm.readonly },
  ]},[(_vm.label)?_c('label',{class:['input__label', { 'sr-only': _vm.labelHidden }],attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input__content position-relative"},[(_vm.prefix)?_c('span',{staticClass:"input__prefix",domProps:{"innerHTML":_vm._s(_vm.prefix)}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{class:_vm.computedInputClassList,attrs:{"id":_vm.name,"aria-labelledby":_vm.name,"name":_vm.name,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"rows":_vm.rows,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"aria-invalid":!!_vm.error,"aria-describedby":`error-${_vm.name}`},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput}}):_c('input',_vm._g({class:_vm.computedInputClassList,attrs:{"id":_vm.name,"aria-labelledby":_vm.name,"type":_vm.type,"name":_vm.name,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"tabindex":_vm.isHoneyPot ? -1 : 0,"readonly":_vm.readonly,"aria-invalid":!!_vm.error,"aria-describedby":`error-${_vm.name}`},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput}},_vm.inputListeners)),(!isNaN(_vm.count) && _vm.maxlength)?_c('p',{staticClass:"input__count"},[_vm._v(" "+_vm._s(_vm.count)+"/"+_vm._s(_vm.maxlength)+" ")]):_vm._e(),(_vm.hasSuffix)?_c('div',{staticClass:"input__suffix"},[_vm._t("suffix")],2):_vm._e()]),(_vm.info)?_c('p',{staticClass:"input__info"},[_vm._v(_vm._s(_vm.info))]):_vm._e(),(_vm.error)?_c('p',{staticClass:"input__error",attrs:{"id":`error-${_vm.name}`}},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }