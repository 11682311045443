import Vue from 'vue';
import VCalendar from 'v-calendar';
import 'lazysizes';
import {
  LayoutPlugin,
  ModalPlugin,
  ProgressPlugin,
  CollapsePlugin,
  CarouselPlugin,
  EmbedPlugin,
  PopoverPlugin,
} from 'bootstrap-vue';
import VeeValidate from 'vee-validate';
import 'focus-visible';
import VueAnalytics from 'vue-analytics';
import VueGtm from 'vue-gtm';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { dictionary } from './validation';

import './assets/scss/setup.scss';
import './assets/scss/main.scss';

const isProduction = process.env.NODE_ENV === 'production';
const googleAnalyticsTrackingId =
  process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID;

if (googleAnalyticsTrackingId) {
  Vue.use(VueAnalytics, {
    id: googleAnalyticsTrackingId,
    router,
    autoTracking: {
      exception: true,
    },
    debug: {
      sendHitTask: isProduction,
    },
    set: [{ field: 'anonymizeIp', value: true }],
  });
}

const googleTagManagerId = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID;

if (googleTagManagerId) {
  Vue.use(VueGtm, {
    id: googleTagManagerId,
    enabled: isProduction,
    vueRouter: router,
  });
}

Vue.use(ModalPlugin);
Vue.use(LayoutPlugin);
Vue.use(ProgressPlugin);
Vue.use(CollapsePlugin);
Vue.use(CarouselPlugin);
Vue.use(EmbedPlugin);
Vue.use(PopoverPlugin);

Vue.use(VCalendar);

Vue.use(VeeValidate, {
  i18n,
  dictionary,
});

Vue.config.productionTip = false;

Vue.prototype.window = window; // This is needed for using window object inside <template>

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
