<template>
  <nav>
    <b-row>
      <b-col cols="6" sm order="2" order-sm="0">
        <Button
          :disabled="!nav.hasPrevious"
          size="small"
          variant="ghost"
          width="auto"
          block
          @click="paginateObject(nav.first)"
        >
          {{ $t('NAV.FIRST') }}
        </Button>
      </b-col>
      <b-col cols="6" sm order="0" order-sm="1">
        <Button
          :disabled="!nav.hasPrevious"
          size="small"
          variant="ghost"
          width="auto"
          block
          @click="paginateObject(nav.previous)"
        >
          &larr; {{ $t('NAV.PREVIOUS') }}
        </Button>
      </b-col>
      <b-col cols="6" sm order="1" order-sm="2">
        <Button
          :disabled="!nav.hasNext"
          size="small"
          variant="ghost"
          width="auto"
          block
          @click="paginateObject(nav.next)"
        >
          {{ $t('NAV.NEXT') }} &rarr;
        </Button>
      </b-col>
      <b-col cols="6" sm order="4" order-sm="3">
        <Button
          :disabled="!nav.hasNext"
          size="small"
          variant="ghost"
          width="auto"
          block
          @click="paginateObject(nav.last)"
        >
          {{ $t('NAV.LAST') }}
        </Button>
      </b-col>
    </b-row>
  </nav>
</template>

<script>
import { Button } from '@/components';

export default {
  name: 'PaginationButtons',
  components: {
    Button,
  },
  props: {
    nav: Object,
  },
  methods: {
    paginateObject(pageTo) {
      this.$emit('paginate', pageTo);
    },
  },
};
</script>
