<template>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center">
          {{ $t('BACK_OFFICE_ORDERS.DELIVERY_ADDRESS') }}
        </th>
        <th>{{ $t('BACK_OFFICE_ORDERS.SERIAL') }}</th>
        <th>{{ $t('BACK_OFFICE_ORDERS.PRODUCT_NAME') }}</th>
        <th>{{ $t('BACK_OFFICE_ORDERS.RETAIL_PRICE') }}</th>
        <th>{{ $t('BACK_OFFICE_ORDERS.TOTAL_AMOUNT') }}</th>
        <th>{{ $t('FORM.NAME') }}</th>
        <th>{{ $t('FORM.MOBILE') }}</th>
        <th>{{ $t('FORM.EMAIL') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          class="text-center text-wrap"
          v-for="(field, index) in formattedOrderDetails"
          :key="index"
        >
          {{ field }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BackOfficeBundleOrderDetails',
  props: {
    orderDetails: Object,
  },
  computed: {
    getAddress() {
      return this.orderDetails.address &&
        this.orderDetails.zipCode &&
        this.orderDetails.city
        ? `${this.orderDetails.address}, ${this.orderDetails.city}, ${this.orderDetails.zipCode}`
        : '';
    },
    formattedOrderDetails() {
      return {
        address: this.getAddress,
        serial: this.orderDetails.serial || '',
        bundleName: this.orderDetails.bundleName || '',
        retailPrice: this.formatPrice(this.orderDetails.retailPrice) || '',
        totalAmount: this.formatPrice(this.orderDetails.totalAmount) || '',
        name: this.orderDetails.name || '',
        mobile: this.orderDetails.mobile || '',
        email: this.orderDetails.email || '',
      };
    },
  },
  methods: {
    formatPrice(price) {
      return `${Number.parseFloat(price).toFixed(2)} zł`;
    },
  },
};
</script>
