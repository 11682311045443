<template>
  <table class="table">
    <tbody>
      <tr>
        <td colspan="2" class="no-padding">
          <table class="table">
            <tr
              v-for="service in asset.services"
              :key="`${asset.id}-${service.id}`"
            >
              <td>
                <div :class="['table__icon table__icon--no-padding']">
                  <ToolsSVG
                    v-if="service.type === SERVICE_TYPES.SERVICE"
                    class="icon table__icon-icon"
                  />

                  <ShieldSVG
                    v-if="service.type === SERVICE_TYPES.INSURANCE"
                    class="icon table__icon-icon"
                  />

                  <GearSVG
                    v-if="service.type === SERVICE_TYPES.INSTALLATION"
                    class="icon table__icon-icon"
                  />

                  <div class="table__icon-text">
                    <p>{{ service.name }}</p>
                  </div>
                </div>
              </td>
              <td class="text-right text-grey text-nowrap vertical-top">
                {{ getServicePeriod(service.period) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr v-if="backOffice.loggedIn">
        <td>{{ $t('BUNDLE_PRODUCT_CODE') }}</td>
        <td class="text-right">
          {{ bundle.productCode }}
        </td>
      </tr>
      <tr>
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_2') }}</td>
        <td class="text-right">
          {{ bundle.totalAmount.toFixed(2) }} {{ $t('CURRENCY_PL') }}
        </td>
      </tr>
      <!--      <tr>-->
      <!--        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_3') }}</td>-->
      <!--        <td class="text-right">-->
      <!--          {{ bundle.financingCost }} {{ $t('CURRENCY') }}-->
      <!--        </td>-->
      <!--      </tr>-->
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_5') }}</td>
        <td class="text-right">
          {{ bundle.upgradePeriod }} {{ $t('MONTHS') }}
        </td>
      </tr>
      <tr v-if="showPeriod">
        <td>{{ $t('YOUR_PLAN_TABLE.CATEGORY_6') }}</td>
        <td class="text-right">{{ bundle.totalPeriod }} {{ $t('MONTHS') }}</td>
      </tr>
      <tr>
        <td class="h5">{{ $t('YOUR_PLAN_TABLE.CATEGORY_TOTAL') }}</td>
        <td class="text-right text-normal">
          <span class="h5">
            {{ bundle.monthlyPaymentAmount.toFixed(2) }} {{ $t('CURRENCY_PL') }}
          </span>
          /
          <span>{{ $t('MONTH_ABBREVIATION') }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ToolsSVG from '@/assets/images/tools.svg';
import ShieldSVG from '@/assets/images/shield.svg';
import GearSVG from '@/assets/images/gear.svg';
import { constants, getServicePeriod } from '@/mixins';

export default {
  name: 'YourPlanTable',
  mixins: [constants, getServicePeriod],
  components: {
    ToolsSVG,
    ShieldSVG,
    GearSVG,
  },
  props: {
    showPeriod: Boolean,
  },
  computed: {
    ...mapState(['backOffice']),
    ...mapGetters(['asset', 'bundle']),
  },
};
</script>

<style lang="scss">
.table__icon {
  position: relative;
  padding-top: rem(4px);
  padding-bottom: rem(4px);
  padding-left: 1rem;

  @include min-width(sm) {
    padding-left: 2rem;
  }
}

.table__icon--no-padding {
  padding-left: 0;
}

.table__icon-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: $color-primary;
  font-size: rem(30px);

  @include min-width(sm) {
    left: 2rem;
  }

  .table__icon--no-padding & {
    left: 0;
  }
}

.table__icon-text {
  padding-left: rem(48px);
}
</style>
