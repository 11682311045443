<template>
  <b-modal
    id="updateSerialAndMerchantReferenceModal"
    :ref="modalRef"
    title="Update serial number and merchant reference modal"
    size="lg"
    hide-footer
    hide-header
  >
    <button type="button" class="modal__close" @click="hideModal">
      <CloseSVG class="icon" />
    </button>
    <Margins>
      <div class="align-items-center">
        <h4>{{ $t('ORDER_ENTER_SERIAL') }}</h4>
      </div>

      <div class="margins__double">
        <Input
          v-model.trim="serialInfoDTO.referenceNumber"
          :label="$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER')"
          name="referenceNumber"
          :readonly="true"
          class="margins__mobile-md-double"
        />
      </div>

      <div class="margins__double">
        <Input
          v-model.trim="serialInfoDTO.serial"
          v-validate="{
            required: true,
            alpha_num: true,
            min: 10,
            max: 12,
            regex: REGEX.alphaNumEN,
          }"
          :label="`${$t('BACK_OFFICE_ORDERS.SERIAL')} *`"
          name="serial"
          :error="errors.first('serial')"
          class="margins__mobile-md-double"
          :data-vv-as="$t('BACK_OFFICE_ORDERS.SERIAL')"
        />
      </div>

      <div class="margins__double">
        <Input
          v-model.trim="serialInfoDTO.merchantReference"
          :label="$t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER')"
          name="merchantReference"
          class="margins__mobile-md-double"
          :data-vv-as="$t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER')"
        />
      </div>

      <div class="text-center margins__double">
        <Button
          :loading="isLoading"
          type="submit"
          :title="$t('SAVE')"
          @click="saveChanges"
        >
          {{ $t('SAVE') }}
        </Button>
      </div>
    </Margins>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UPDATE_SERIAL_AND_MERCHANT_REFERENCE } from '@/store/types';
import { Button, Margins, Input } from '@/components';
import { constants } from '@/mixins';
import CloseSVG from '@/assets/images/times-circle-regular.svg';

export default {
  name: 'UpdateSerialAndMerchantReferenceModal',
  components: {
    Margins,
    CloseSVG,
    Input,
    Button,
  },
  mixins: [constants],
  data() {
    return {
      serialInfoDTO: {
        referenceNumber: null,
        serial: null,
        merchantReference: null,
      },
    };
  },
  computed: {
    ...mapState(['isLoading']),
    modalRef() {
      return `update-serial-and-merchant-reference-modal`;
    },
  },
  methods: {
    ...mapActions([UPDATE_SERIAL_AND_MERCHANT_REFERENCE]),
    saveChanges() {
      this.$emit('update-serial-and-merchant-reference', this.serialInfoDTO);
      this.hideModal();
    },
    showModal(referenceNumber, serial, merchantReference) {
      if (this.serialInfoDTO.referenceNumber !== referenceNumber) {
        this.serialInfoDTO = {
          ...this.serialInfoDTO,
          serial,
          merchantReference,
          referenceNumber,
        };
      }
      this.$bvModal.show('updateSerialAndMerchantReferenceModal');
    },
    hideModal() {
      this.$bvModal.hide('updateSerialAndMerchantReferenceModal');
    },
  },
};
</script>
