<template>
  <b-progress :max="max" show-progress show-value>
    <b-progress-bar :value="value">
      <span class="progress-bar__unit">{{ unitLabel }}</span>
      <span class="progress-bar__value">{{ value }}</span>
    </b-progress-bar>
    <div class="progress-bar__max">{{ max }}</div>
  </b-progress>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    value: Number,
    max: Number,
    unitLabel: String,
  },
};
</script>

<style lang="scss">
.progress {
  position: relative;
  justify-content: space-between;
  background-color: $color-grey-background !important;
  border-radius: $border-radius !important;
}

.progress-bar {
  position: relative;
  border-radius: 0;
  background-color: $color-primary !important;
  z-index: 2;
}

.progress-bar__unit {
  position: absolute;
  left: rem(12px);
  top: 50%;
  transform: translateY(-50%);
}

.progress-bar__value {
  position: absolute;
  right: rem(12px);
  top: 50%;
  transform: translateY(-50%);
}

.progress-bar__max {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: $color-black;
  margin-right: rem(12px);
  z-index: 1;
}
</style>
