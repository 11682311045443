<template>
  <div class="language-switcher">
    <Select
      name="languages"
      label="Select language"
      label-hidden
      v-model="lang"
      :options="selectOptions"
      @change="setLanguage"
      size="extra-small"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { SET_LANGUAGE } from '@/store/types';
import { Select } from '@/components';
import { setHtmlElementLanguage, setDocumentTitle } from '@/utils';
import { constants } from '@/mixins';

export default {
  name: 'LanguageSwitcher',
  mixins: [constants],
  components: {
    Select,
  },
  data() {
    return {
      lang: this.$i18n.locale,
    };
  },
  watch: {
    language(val) {
      this.lang = val;
    },
  },
  methods: {
    ...mapMutations([SET_LANGUAGE]),
    setLanguage(value) {
      this.$i18n.locale = value;
      const titleKey = this.$router.currentRoute.meta.titleKey;
      setDocumentTitle(
        titleKey ? this.$t(`ROUTER.${titleKey}`) : null,
        this.$t('ROUTER.DEFAULT_TITLE'),
      );
      setHtmlElementLanguage(value);
      this.SET_LANGUAGE(value);
    },
  },
  computed: {
    ...mapState(['language', 'storeOptions']),
    selectOptions() {
      return this.storeOptions.availableLocales.map((lang) => ({
        value: lang,
        label: this.$t(`LANGUAGES.${lang.toUpperCase()}`),
      }));
    },
  },
};
</script>

<style lang="scss">
.language-switcher {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
