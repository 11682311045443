var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.to ? 'router-link' : _vm.href ? 'a' : 'button',{tag:"component",class:[
    'button',
    { [`button--${_vm.variant}`]: !!_vm.variant },
    { [`button--${_vm.size}`]: !!_vm.size },
    { [`button--color-${_vm.color}`]: !!_vm.color },
    { [`button--border-${_vm.border}`]: !!_vm.border },
    { ['button--block']: _vm.block },
    { ['button--active']: _vm.active },
    { [`button--width-${_vm.width}`]: !!_vm.width },
  ],attrs:{"tag":_vm.tag,"to":_vm.to,"href":_vm.href,"target":_vm.target,"rel":_vm.target === '_blank' ? 'noopener noreferrer' : null,"type":_vm.isLink ? null : _vm.type,"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('span',{staticClass:"button__content"},[_vm._t("icon"),_c('span',{staticClass:"button__text"},[_vm._t("default")],2),_vm._t("icon-right"),(_vm.loading)?_c('LoadingSVG',{staticClass:"icon"}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }