<template>
  <div id="app" class="app">
    <Header v-if="!IS_MAINTENANCE">
      <template #button-slot v-if="isUserAdmin">
        <Button variant="text" @click="toggleVisibility(true)">
          <p class="text-primary button-content-text">
            {{ $t('SEND_INVITATIONS') }}
          </p>
        </Button>
      </template>
    </Header>
    <main>
      <transition name="fade-router" mode="out-in">
        <router-view />
      </transition>

      <Alert v-if="hasError" type="error" position="fixed" isDismissable>
        <Margins>
          <p v-html="errorMessage" />
          <p class="text-extra-small" v-html="errorCode"></p>
        </Margins>
      </Alert>

      <Alert v-if="notification" type="success" position="fixed" isDismissable>
        <Margins>
          <p v-html="notification" />
        </Margins>
      </Alert>
      <InvitationModal
        v-model="isInvitationModalVisible"
        @change="toggleVisibility"
      />
    </main>
    <Footer />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Header, Footer } from '@/containers';
import { Alert, Margins, Button } from '@/components';
import SessionHandler from './session';
import { constants } from '@/mixins';
import { SUPPORT_EMAIL, USER_ROLE } from '@/constants';
import InvitationModal from '@/containers/BackOffice/InvitationModal.vue';

export default {
  name: 'App',
  mixins: [constants],
  components: {
    InvitationModal,
    Header,
    Footer,
    Alert,
    Margins,
    Button,
  },
  data() {
    return {
      isInvitationModalVisible: false,
    };
  },
  mounted() {
    this.handleSessionTimeout(this.backOfficeLoggedIn);
  },
  methods: {
    handleSessionTimeout(isLoggedIn) {
      if (isLoggedIn) {
        SessionHandler.initSessionHandler();
      } else {
        SessionHandler.clearSessionHandler();
      }
    },
    toggleVisibility(isVisible) {
      this.isInvitationModalVisible = isVisible;
    },
  },
  computed: {
    ...mapState(['hasError', 'notification', 'backOffice']),
    ...mapGetters(['authenticatedUser']),
    backOfficeLoggedIn() {
      return this.backOffice.loggedIn;
    },
    errorMessage() {
      return this.$t('DEFAULT_ERROR_MESSAGE', [
        '<br />',
        `<a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
      ]);
    },
    errorCode() {
      return this.$t('DEFAULT_ERROR_CODE', [
        this.hasError.message || this.hasError.status,
      ]);
    },
    isUserAdmin() {
      return this.authenticatedUser.role === USER_ROLE.ADMIN;
    },
  },
  watch: {
    backOfficeLoggedIn(isLoggedIn) {
      console.debug('watch is logged in: ', isLoggedIn);
      this.handleSessionTimeout(isLoggedIn);
    },
  },
};
</script>

<style lang="scss">
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.fade-router-enter-active,
.fade-router-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-router-enter,
.fade-router-leave-active {
  opacity: 0;
}

.button-content-text {
  text-transform: none;
}
</style>
