<template>
  <div class="sign-out">
    <Button variant="ghost" width="auto" :title="$t('LOGOUT')" @click="logout">
      <SignOutSVG class="icon" />
    </Button>
  </div>
</template>

<script>
import SignOutSVG from '@/assets/images/sign-out-alt-solid.svg';
import { BACK_OFFICE_LOGOUT } from '@/store/types';
import { mapActions } from 'vuex';
import { Button } from '@/components';

export default {
  name: 'Logout',
  components: {
    SignOutSVG,
    Button,
  },
  methods: {
    ...mapActions([BACK_OFFICE_LOGOUT]),
    async logout() {
      this.BACK_OFFICE_LOGOUT();
    },
  },
};
</script>
