<template>
  <section :class="['content', { [`content--size-${size}`]: size }]">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'Container',
  props: {
    size: String,
  },
};
</script>

<style lang="scss">
.content {
  width: 100%;
  max-width: rem(980px);
  padding-right: $grid-gutter-width-mobile / 2;
  padding-left: $grid-gutter-width-mobile / 2;
  margin-right: auto;
  margin-left: auto;

  @include min-width(md) {
    padding-right: $grid-gutter-width / 2;
    padding-left: $grid-gutter-width / 2;
  }
}

.content--size-large {
  max-width: rem(1314px);
}

.content--size-full {
  max-width: 100%;
}
</style>
