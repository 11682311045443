<template>
  <b-modal
    :ref="modalRef"
    title="Annul application modal"
    size="lg"
    hide-footer
    hide-header
  >
    <button type="button" class="modal__close" @click="hideModal">
      <CloseSVG class="icon" />
    </button>
    <Margins>
      <h4>{{ $t('WARNING') }}</h4>

      <div class="text-center margins__triple">
        <span v-html="warningMessage" />
      </div>

      <ButtonGroup margin="small" class="text-center margins__double">
        <Button
          type="submit"
          @click="annulApplication()"
          :title="$t('YES')"
          width="auto"
          margin="small"
          >{{ $t('YES') }}
        </Button>
        <Button
          type="submit"
          @click="hideModal()"
          :title="$t('NO')"
          width="auto"
          margin="small"
          >{{ $t('NO') }}
        </Button>
      </ButtonGroup>

      <div class="text-center"></div>
    </Margins>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { ANNUL_ORDER } from '@/store/types';
import { Button, Margins } from '@/components';
import { constants } from '@/mixins';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import ButtonGroup from '@/components/ButtonGroup';

export default {
  name: 'ApplicationDeleteModal',
  mixins: [constants],
  components: {
    Margins,
    CloseSVG,
    Button,
    ButtonGroup,
  },
  data() {
    return {
      orderDTO: {
        referenceNumber: null,
      },
    };
  },
  methods: {
    ...mapActions([ANNUL_ORDER]),
    async annulApplication() {
      this.ANNUL_ORDER(this.orderDTO.referenceNumber);
      this.hideModal();
    },
    showModal(order) {
      this.orderDTO = order;
      this.$refs[this.modalRef].show();
    },
    hideModal() {
      this.$refs[this.modalRef].hide();
    },
  },
  computed: {
    modalRef() {
      return `annul-modal`;
    },
    warningMessage() {
      return this.$t('ANNUL_ORDER_WARNING', [
        `<b>${this.orderDTO.referenceNumber}</b>`,
      ]);
    },
  },
};
</script>
