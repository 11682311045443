<template>
  <hr
    :class="[
      'separator',
      {
        'separator--line': hasLine,
        [`separator--${size}`]: !!size,
      },
    ]"
  />
</template>

<script>
export default {
  name: 'Separator',
  props: {
    size: {
      validator: function (value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },
    hasLine: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.separator {
  border: none;
  border-top: rem(1px) solid transparent;
  margin: 0;

  .card--padding-small & {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.separator--small {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.separator--medium {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.separator--large {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.separator--line {
  border-color: $color-grey-border;
}
</style>
