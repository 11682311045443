import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Home from './views/Home.vue';
import Maintenance from './views/Maintenance.vue';

import store from './store';
import {
  SET_LANGUAGE,
  HAS_ERROR,
  NOTIFICATION,
  GET_STORE_FROM_SHORT_NAME,
  SET_BUNDLE_FROM_REFERENCE_NUMBER,
} from './store/types';
import { STEPS, ROUTES, DECISION_TYPES } from './constants';
import i18n from './i18n';
import {
  setDocumentTitle,
  setHtmlElementLanguage,
  kebabToSnakeCaseForTranslation,
} from './utils';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 250);
    });
  },
  routes: [
    {
      path: ROUTES.ROOT.path,
      name: ROUTES.ROOT.name,
    },
    {
      path: ROUTES.HOME.path,
      name: ROUTES.HOME.name,
      component: Home,
    },
    {
      path: ROUTES.MAINTENANCE.path,
      name: ROUTES.MAINTENANCE.name,
      component: Maintenance,
    },
    {
      path: ROUTES.FAQ.path,
      name: ROUTES.FAQ.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.FAQ.name),
      },
      component: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue'),
    },
    {
      path: ROUTES.LOAN_APPLICATION.path,
      name: ROUTES.LOAN_APPLICATION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.LOAN_APPLICATION.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application" */ './views/LoanApplication/LoanApplication.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION_SHORT.path,
      name: ROUTES.LOAN_APPLICATION_SHORT.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.LOAN_APPLICATION_SHORT.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application" */ './views/LoanApplication/LoanApplication.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION_LOADING.path,
      name: ROUTES.LOAN_APPLICATION_LOADING.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.LOAN_APPLICATION_LOADING.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application-loading" */ './views/LoanApplication/LoanApplicationLoading.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION_COMPLETE.path,
      name: ROUTES.LOAN_APPLICATION_COMPLETE.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.LOAN_APPLICATION_COMPLETE.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application-complete" */ './views/LoanApplication/Complete.vue'
        ),
    },
    {
      path: ROUTES.DECISION_SUCCESS.path,
      name: ROUTES.DECISION_SUCCESS.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.DECISION_SUCCESS.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-success" */ './views/Decisions/Success.vue'
        ),
    },
    {
      path: ROUTES.DECISION_PENDING.path,
      name: ROUTES.DECISION_PENDING.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.DECISION_PENDING.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-pending" */ './views/Decisions/Pending.vue'
        ),
    },
    {
      path: ROUTES.DECISION_CANCELLED.path,
      name: ROUTES.DECISION_CANCELLED.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.DECISION_CANCELLED.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-cancelled" */ './views/Decisions/Cancelled.vue'
        ),
    },
    {
      path: ROUTES.DECISION_DENIED.path,
      name: ROUTES.DECISION_DENIED.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.DECISION_DENIED.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-denied" */ './views/Decisions/Denied.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE.path,
      name: ROUTES.BACK_OFFICE.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BACK_OFFICE.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office" */ './views/BackOffice/Home.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_APPLICATION.path,
      name: ROUTES.BACK_OFFICE_APPLICATION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BACK_OFFICE.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office-application" */ './views/BackOffice/BackOfficeApplication.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_LOGIN.path,
      name: ROUTES.BACK_OFFICE_LOGIN.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BACK_OFFICE_LOGIN.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office-login" */ './views/BackOffice/Login.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_REGISTRATION.path,
      name: ROUTES.BACK_OFFICE_REGISTRATION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.BACK_OFFICE_REGISTRATION.name,
        ),
      },
      props: (route) => ({
        invitationId: route.params.invitationId,
      }),
      component: () =>
        import(
          /* webpackChunkName: "back-office-registration" */ './views/BackOffice/Registration.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_PASSWORD_RESET.path,
      name: ROUTES.BACK_OFFICE_PASSWORD_RESET.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.BACK_OFFICE_PASSWORD_RESET.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office-password-reset" */ './views/BackOffice/PasswordReset.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_PASSWORD_LOST.path,
      name: ROUTES.BACK_OFFICE_PASSWORD_LOST.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.BACK_OFFICE_PASSWORD_LOST.name,
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office-password-lost" */ './views/BackOffice/PasswordLost.vue'
        ),
    },
    {
      path: ROUTES.PAGE_404.path,
      name: ROUTES.PAGE_404.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.PAGE_404.name),
      },
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue'),
    },
    {
      path: '*',
      redirect: { name: ROUTES.PAGE_404.name },
    },
    {
      path: ROUTES.BUNDLES.path,
      name: ROUTES.BUNDLES.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BUNDLES.name),
      },
      component: () =>
        import(/* webpackChunkName: "404" */ './views/BackOffice/Bundles.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_MAINTENANCE === 'true') {
    if (to.name !== ROUTES.MAINTENANCE.name) {
      router.replace({
        path: ROUTES.MAINTENANCE.path,
        redirect: ROUTES.MAINTENANCE.path,
      });
    }
    next();
  } else {
    handleBeforeRoute(to, from, next);
  }
});

router.afterEach((to) => {
  const { commit } = store;
  // On every route change set new document title
  setDocumentTitle(
    to.meta.titleKey ? i18n.t(`ROUTER.${to.meta.titleKey}`) : null,
    i18n.t('ROUTER.DEFAULT_TITLE'),
  );

  // If url has query param ?lang=, set locale
  let languageFromQuery = to.query.lang;
  if (['smart-dev.fairown.com', 'smart.fairown.com'].includes(location.host)) {
    languageFromQuery = 'pl';
  }

  if (i18n.availableLocales.includes(languageFromQuery)) {
    i18n.locale = languageFromQuery;
    setHtmlElementLanguage(languageFromQuery);
    setDocumentTitle(
      to.meta.titleKey ? i18n.t(`ROUTER.${to.meta.titleKey}`) : null,
      i18n.t('ROUTER.DEFAULT_TITLE'),
    );
    commit(SET_LANGUAGE, languageFromQuery);
  }
});

const handleBeforeRoute = (to, from, next) => {
  const {
    state: { backOffice, currentStore, hasError },
    commit,
    dispatch,
  } = store;

  if (
    !backOffice.authenticatedUser &&
    to.query.store &&
    currentStore?.shortName !== to.query.store
  ) {
    dispatch(GET_STORE_FROM_SHORT_NAME, to.query.store);
  }
  if (to.query.ref) {
    dispatch(SET_BUNDLE_FROM_REFERENCE_NUMBER, to.query.ref);
  }

  let goto = getValidRoute(to);

  if (hasError) {
    commit(HAS_ERROR, false);
  }
  commit(NOTIFICATION, null);

  goto ? next(goto) : next();
};

const getValidRoute = (to) => {
  const {
    ROOT,
    HOME,
    LOAN_APPLICATION,
    LOAN_APPLICATION_LOADING,
    LOAN_APPLICATION_COMPLETE,
    DECISION_SUCCESS,
    DECISION_DENIED,
    BACK_OFFICE,
    BACK_OFFICE_APPLICATION,
    BACK_OFFICE_LOGIN,
    BUNDLES,
  } = ROUTES;
  const {
    state: {
      currentStep,
      loanApplicationData,
      loanApplicationDecision,
      backOffice,
      productCode,
      currentStore,
    },
  } = store;

  let goto;

  switch (to.name) {
    case ROOT.name:
      goto = {
        name: HOME.name,
        params: { productCode },
      };
      break;
    case HOME.name:
      if (
        (!productCode && !to.params.productCode) ||
        (!backOffice.authenticatedUser &&
          !to.query.store &&
          !currentStore?.shortName)
      ) {
        goto = {
          name: ROUTES.PAGE_404.name,
        };
      } else if (
        (loanApplicationData?.referenceNumber ||
          loanApplicationData?.purchase?.purchaseReference) &&
        currentStore?.shortName
      ) {
        goto = {
          name: LOAN_APPLICATION.name,
          query: {
            ref: loanApplicationData?.referenceNumber
              ? loanApplicationData.referenceNumber
              : loanApplicationData.purchase.purchaseReference,
            store: currentStore?.shortName,
          },
          replace: true,
        };
      } else if (
        !backOffice.authenticatedUser &&
        !to.query.store &&
        currentStore?.shortName
      ) {
        goto = {
          name: ROUTES.LOAN_APPLICATION.name,
          params: { productCode: productCode || to.params.productCode },
          query: { store: currentStore.shortName },
          replace: true,
        };
      }
      break;
    case LOAN_APPLICATION.name:
      if (currentStep > STEPS.clientRedirected) {
        goto = {
          name: HOME.name,
          params: { productCode },
        };
      }
      break;
    case LOAN_APPLICATION_LOADING.name:
      if (currentStep > STEPS.apply || !loanApplicationData) {
        goto = {
          name: HOME.name,
          params: { productCode },
        };
      }
      break;
    case LOAN_APPLICATION_COMPLETE.name:
      if (!to.params.pathMatch) {
        goto = {
          name: HOME.name,
          params: { productCode },
        };
      }
      break;
    case DECISION_SUCCESS.name:
      if (
        currentStep < STEPS.decision ||
        ![
          DECISION_TYPES.SIGN_CONTRACT,
          DECISION_TYPES.CONTRACT_SIGNED,
          DECISION_TYPES.GRANTED,
          DECISION_TYPES.COMPLETED,
          DECISION_TYPES.SIGNED_DEAL,
          DECISION_TYPES.SIGNED_DEAL_NO_ADVANCE,
          DECISION_TYPES.SIGNED_DEAL_YOUR_ADVANCE,
          DECISION_TYPES.SIGNED_DEAL_OFFLINE,
        ].includes(loanApplicationDecision.status)
      ) {
        goto = {
          name: HOME.name,
          params: { productCode },
        };
      }
      break;
    case DECISION_DENIED.name:
      if (
        currentStep < STEPS.decision ||
        ![
          DECISION_TYPES.REJECTED,
          DECISION_TYPES.DECLINED,
          DECISION_TYPES.NO_LOAN_POSSIBLE,
        ].includes(loanApplicationDecision.status)
      ) {
        goto = {
          name: HOME.name,
          params: { productCode },
        };
      }
      break;
    case BACK_OFFICE.name:
    case BACK_OFFICE_APPLICATION.name: {
      if (!backOffice.loggedIn) {
        goto = { name: BACK_OFFICE_LOGIN.name };
      }
      break;
    }
    case BACK_OFFICE_LOGIN.name: {
      if (backOffice.loggedIn) {
        goto = { name: BACK_OFFICE.name };
      }
      break;
    }
    case BUNDLES.name: {
      if (!backOffice.loggedIn) {
        goto = { name: BACK_OFFICE_LOGIN.name };
      }
      break;
    }
    default:
  }
  return goto;
};

export default router;
