var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'badge',
    { [`badge--variant-${_vm.variant}`]: !!_vm.variant },
    { [`badge--border-radius-${_vm.borderRadius}`]: !!_vm.borderRadius },
    { [`badge--clickable`]: _vm.clickable },
    { [`badge--active`]: _vm.active },
  ],on:{"click":function($event){_vm.clickable ? _vm.$emit('click') : null}}},[(_vm.clickable && _vm.active)?_c('CheckSVG',{staticClass:"icon badge__icon"}):_vm._e(),_c('span',{staticClass:"badge__content"},[_vm._t("default")],2),(_vm.hasRemoveButton)?_c('button',{staticClass:"badge__button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[_c('CloseSVG',{staticClass:"icon"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }