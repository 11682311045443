<template>
  <footer class="footer">
    <Container class="text-small">
      <b-row v-if="isAuthenticated">
        <b-col sm class="text-center">
          <Margins class="margins__mobile-sm footer__left">
            <p class="text-strong">
              {{ $t('FOOTER.FAIROWN_SUPPORT') }}
            </p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <a
                  class="footer__list-item"
                  :href="`mailto:${$t('FOOTER.FAIROWN_SUPPORT_EMAIL')}`"
                  >{{ $t('FOOTER.FAIROWN_SUPPORT_EMAIL') }}</a
                >
              </li>
            </ul>
          </Margins>
        </b-col>
        <b-col sm class="text-center">
          <Margins class="margins__mobile-sm">
            <p class="text-strong">
              {{ $t('FOOTER.INBANK_SUPPORT') }}
            </p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <span>{{ $t(`COUNTRIES.PL`) }}:</span>
                <a
                  :href="`tel:${$t(`FOOTER.INBANK_SUPPORT_NUMBER_PL`).replace(
                    /\s+/g,
                    '',
                  )}`"
                >
                  {{ $t(`FOOTER.INBANK_SUPPORT_NUMBER_PL`) }}
                </a>
              </li>
            </ul>
          </Margins>
        </b-col>
        <b-col sm class="text-center">
          <Margins class="margins__mobile-sm footer__right">
            <!--
            TODO: hide comment until documents link becomes needed
            Hide task: https://fairown.atlassian.net/browse/PBS-31
            Un-hide task: https://fairown.atlassian.net/browse/PBS-32

            <p class="text-strong">{{ $t('FOOTER.DOCUMENTS') }}</p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <a
                  href="https://drive.google.com/file/d/1JJmjuaiWLDDOGxKNlEfDCVVWfMyvlNs2/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  >{{ $t('FOOTER.USER_MANUAL') }}</a
                >
              </li>
            </ul>
            -->
          </Margins>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col md="8">
          <p>{{ $t('FOOTER.DESCRIPTION') }}</p>
        </b-col>
        <b-col>
          <Margins size="05x" class="footer__right margins__mobile-sm">
            <p>
              {{ $t('FOOTER.CONTACT') }}
            </p>
            <ul class="footer__list">
              <li class="footer__list-item">
                <a :href="`mailto:${$t('FOOTER.EMAIL')}`">{{
                  $t('FOOTER.EMAIL')
                }}</a>
              </li>
              <li class="footer__list-item">
                <a :href="`tel:${$t('FOOTER.PHONE').replace(/\s/g, '')}`">{{
                  $t('FOOTER.PHONE')
                }}</a>
              </li>
            </ul>
          </Margins>
        </b-col>
      </b-row>
    </Container>
  </footer>
</template>

<script>
import { Container, Margins } from '@/components';
import { mapGetters } from 'vuex';
import { constants } from '@/mixins';

export default {
  name: 'Footer',
  mixins: [constants],
  components: {
    Container,
    Margins,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
};
</script>

<style lang="scss">
.footer {
  border-top: rem(1px) solid $color-grey-background;
  background: $color-grey-background;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer__list-item + .footer__list-item {
  margin-top: 0.25rem;
}

.footer__right {
  @include min-width(md) {
    text-align: right;
  }
}
.footer__left {
  @include min-width(md) {
    text-align: left;
  }
}
</style>
