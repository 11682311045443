<template>
  <div class="check">
    <input
      type="checkbox"
      class="check__input"
      :id="name"
      :name="name"
      :checked="value"
      :aria-invalid="!!error"
      :aria-describedby="`error-${name}`"
      @input="updateInput"
      @change="changeInput"
    />
    <label :for="name" class="check__label">
      <span class="check__indicator" />
      <span class="check__label-text">
        <slot>
          <span v-html="label" />
        </slot>
      </span>
    </label>
    <p v-if="error" :id="`error-${name}`" class="check__error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'Check',
  inject: ['$validator'],
  props: {
    label: String,
    name: {
      type: String,
      required: true,
    },
    value: Boolean,
    error: String,
  },
  methods: {
    updateInput(e) {
      this.$emit('input', e.target.checked || undefined);
    },
    changeInput(e) {
      this.$emit('change', e.target.checked || undefined);
    },
  },
};
</script>

<style lang="scss">
.check {
  position: relative;
}

.check__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.check__label {
  cursor: pointer;
}

.check__label-text {
  display: flex;
  align-items: center;
  font-size: $font-size-small;
  padding-left: rem(36px);
  min-height: rem(24px);
}

.check__indicator {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: rem(24px);
  width: rem(24px);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: rem(24px);
    width: rem(24px);
    border-radius: 0;
    border: rem(2px) solid $color-grey-border;
  }
}

.check__input:checked + .check__label .check__indicator::before {
  background: $color-primary no-repeat center center;
  background-size: 60%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='28' height='27' viewBox='0 0 28 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.02549 11.5597C3.37581 10.6664 2.12498 10.4689 1.23168 11.1186C0.338371 11.7682 0.140872 13.0191 0.790549 13.9124L4.02549 11.5597ZM10.6466 24.064L9.02909 25.2404C9.41218 25.7671 10.0278 26.0743 10.679 26.0637C11.3303 26.0532 11.9356 25.7262 12.3014 25.1873L10.6466 24.064ZM26.7188 3.94725C27.3392 3.03334 27.1012 1.78957 26.1873 1.16922C25.2734 0.548867 24.0296 0.786844 23.4092 1.70076L26.7188 3.94725ZM0.790549 13.9124L9.02909 25.2404L12.264 22.8877L4.02549 11.5597L0.790549 13.9124ZM12.3014 25.1873L26.7188 3.94725L23.4092 1.70076L8.99178 22.9408L12.3014 25.1873Z' fill='white'/%3E%3C/svg%3E");
  border-color: $color-primary;
}

.check__input.focus-visible + .check__label .check__indicator::before {
  border-color: $color-black-light;
  box-shadow: 0 0 0 1px $color-black-light;
}

.check__error {
  font-size: $font-size-small;
  color: $color-error;
  margin-top: rem(6px);
}
</style>
