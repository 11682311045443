<template>
  <div>
    <b-row align-h="center">
      <b-col>
        <ApplyAssetCard />
      </b-col>
      <b-col md="8">
        <Margins class="margins__mobile-md-double">
          <h5>{{ bundle.name }}</h5>
          <YourPlanList />
          <Progress
            :value="bundle.upgradePeriod"
            :max="bundle.totalPeriod"
            :unitLabel="$t('MONTHS')"
          />
          <table class="table">
            <tbody>
              <tr style="border-top: none">
                <td class="no-padding">
                  <strong class="text-size-big">
                    {{ $t('YOUR_PLAN_TABLE.CATEGORY_TOTAL') }}
                  </strong>
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  <strong class="text-size-big">
                    {{ bundle.monthlyPaymentAmount.toFixed(2) }}
                    {{ $t('CURRENCY_PL') }}
                  </strong>
                </td>
              </tr>
              <tr style="border-top: none">
                <td class="no-padding">
                  <strong>
                    {{ $t('YOUR_PLAN_TABLE.PL_CATEGORY_3') }}
                  </strong>
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  <strong>
                    {{ bundle.totalAmount.toFixed(2) }}
                    {{ $t('CURRENCY_PL') }}
                  </strong>
                </td>
              </tr>
              <tr style="border-top: none">
                <td class="no-padding">
                  {{ $t('YOUR_PLAN_TABLE.PL_CATEGORY_4') }}
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  {{ bundle.retailPrice.toFixed(2) }}
                  {{ $t('CURRENCY_PL') }}
                </td>
              </tr>
              <tr style="border-top: none">
                <td class="no-padding">
                  {{
                    $t('YOUR_PLAN_TABLE.PL_CATEGORY_1', [bundle.upgradePeriod])
                  }}
                </td>
                <td class="text-right text-normal no-padding whitespace-nowrap">
                  {{
                    (
                      bundle.monthlyPaymentAmount * bundle.upgradePeriod
                    ).toFixed(2)
                  }}
                  {{ $t('CURRENCY_PL') }}
                </td>
              </tr>
            </tbody>
          </table>
        </Margins>
      </b-col>
    </b-row>

    <Separator hasLine size="medium" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ApplyAssetCard, YourPlanList } from '@/containers/Apply';
import { Margins, Progress, Separator } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'Product',
  mixins: [constants],
  components: {
    ApplyAssetCard,
    Margins,
    Progress,
    YourPlanList,
    Separator,
  },
  computed: {
    ...mapGetters(['asset', 'bundle']),
  },
};
</script>

<style lang="scss">
.text-size-big {
  font-size: 20px;
}
</style>
