import ApiService from '@/api';
import store from '@/store';
import { BACK_OFFICE_LOGOUT } from '@/store/types';

class SessionHandler {
  isUserActive = false;
  checkUserActivityIntervalId = null;
  logoutTimerId = null;

  initSessionHandler() {
    console.debug('init session handler');
    this.checkUserActivityIntervalId = setInterval(
      this.checkIfUserIsActive,
      60 * 1000,
    );
    window.document.addEventListener('keydown', this.userActed);
    window.document.addEventListener('mousedown', this.userActed);
  }

  clearSessionHandler() {
    console.debug('clear session handler');
    clearInterval(this.checkUserActivityIntervalId);
    this.clearLogoutTimer();
    window.document.removeEventListener('keydown', this.userActed);
    window.document.removeEventListener('mousedown', this.userActed);
    this.isUserActive = false;
    this.checkUserActivityIntervalId = null;
  }

  userActed = () => {
    console.debug(this);
    this.isUserActive = true;
    console.debug('user acted: ', this);
  };

  checkIfUserIsActive = async () => {
    console.debug(this);
    console.debug('is user active interval: ', this.isUserActive);
    if (this.isUserActive) {
      console.debug('keep session alive');
      await ApiService.alive();
      this.clearLogoutTimer();
      this.isUserActive = false;
      console.debug('reset user is active: ', this.isUserActive);
    } else if (!this.logoutTimerId) {
      this.createLogoutTimer();
    }
  };

  createLogoutTimer() {
    console.debug('add logout timer');
    this.logoutTimerId = setTimeout(async () => {
      this.clearLogoutTimer();
      console.debug('perform logout');
      store.dispatch(BACK_OFFICE_LOGOUT);
    }, process.env.VUE_APP_LOGOUT_IN_SECONDS * 1000);
  }

  clearLogoutTimer() {
    if (this.logoutTimerId) {
      console.debug('remove logout timer');
      clearTimeout(this.logoutTimerId);
      this.logoutTimerId = null;
    }
  }
}

export default new SessionHandler();
