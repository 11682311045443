<template>
  <form
    @submit="handleSubmit"
    class="loan-application-form"
    autocomplete="off"
    :class="{ margins__triple: !isAuthenticated }"
  >
    <Input
      name="bot-field"
      label="Don’t fill this out if you're human"
      v-model="formData.honeypot"
      autocomplete="random"
      isHoneyPot
    />
    <Margins>
      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.FIRST_NAME')} *`"
            name="firstName"
            autocomplete="given-name"
            v-model.trim="formData.customerData.firstName"
            :error="errors.first('firstName')"
            v-validate="{
              required: true,
              regex: REGEX.INBANK.PL.name,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.LAST_NAME')} *`"
            name="surname"
            autocomplete="family-name"
            v-model.trim="formData.customerData.lastName"
            :error="errors.first('surname')"
            v-validate="{
              required: true,
              regex: REGEX.INBANK.PL.name,
            }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <Input
            :label="`${$t('FORM.ID_NUMBER')} *`"
            name="personalIdentityCode"
            placeholder="00000000000"
            v-model.trim="formData.customerData.identityCode"
            :error="errors.first('personalIdentityCode')"
            v-validate="'required|personalCodePL'"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.STREET')} *`"
            name="street"
            v-model.trim="deliveryInfo.street"
            :error="errors.first('street')"
            v-validate="{ required: true, max: 50 }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.STREET')"
          />
        </b-col>
        <b-col>
          <Input
            :label="`${$t('FORM.HOUSE_NO')} *`"
            name="houseNumber"
            v-model.trim="deliveryInfo.houseNumber"
            :error="errors.first('houseNumber')"
            v-validate="{ required: true, max: 10 }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.HOUSE_NO')"
          />
        </b-col>
        <b-col>
          <Input
            :label="`${$t('FORM.APARTMENT_NO')}`"
            name="apartmentNumber"
            v-model.trim="deliveryInfo.apartmentNumber"
            v-validate="{ max: 10 }"
            :error="errors.first('apartmentNumber')"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.APARTMENT_NO')"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.ZIP_CODE')} *`"
            name="zipCode"
            v-model.trim="deliveryInfo.zipCode"
            :error="errors.first('zipCode')"
            v-validate="{ required: true, regex: REGEX.INBANK.PL.zipCode }"
            :maxlength="6"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.ZIP_CODE')"
            @input="formatZipCode"
          />
        </b-col>
        <b-col md="6">
          <Input
            :label="`${$t('FORM.CITY')} *`"
            name="city"
            v-model.trim="deliveryInfo.city"
            :error="errors.first('city')"
            v-validate="{ required: true, max: 50 }"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.CITY')"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col md="6">
          <Input
            type="email"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.customerContactData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('FORM.VALIDATOR.EMAIL')"
          />
        </b-col>
        <b-col md="6">
          <MobileInput
            ref="mobileInput"
            :default-country="STORE.PL.country.toUpperCase()"
            mode="international"
            name="mainMobileNo"
            :label="`${$t('FORM.MOBILE')} *`"
            dynamic-placeholder
            :onlyCountries="[STORE.PL.country.toUpperCase()]"
            valid-characters-only
            disabled-fetching-country
            :value="numberObject.number.international"
            :error="errors.first('mainMobileNo')"
            v-validate="'required|inbankPhonePL'"
            @input="changeMobileNumber"
            @validate="populateNumberObject"
            class="margins__mobile-md-double"
            :data-vv-as="$t('FORM.VALIDATOR.MOBILE')"
          />
        </b-col>
      </b-row>

      <Separator hasLine size="large" />

      <Check
        :label="$t('FORM.CONSENTS.SELECT_ALL')"
        name="selectAll"
        v-model="formData.selectAll"
        @input="toggleSelectAll"
        v-validate="{ required: false }"
      />

      <Check
        :label="fairownConsent"
        name="fairownConsent"
        v-model="formData.fairownConsent"
        v-validate="{ required: true }"
      />

      <Check
        :label="proaTermsAndConditionsConsentText"
        name="proaTermsAndConditionsConsent"
        v-model="proaTermsAndConditionsConsent"
        v-validate="{ required: true }"
      />

      <div
        v-if="
          errors.first('fairownConsent') ||
          errors.first('proaTermsAndConditionsConsent')
        "
        class="check__error"
      >
        {{ $t('FORM.VALIDATOR.TERMS') }}
      </div>

      <div class="text-small text-center">
        <i18n path="FORM.CONSENTS.PRIVACY_POLICY_LINKS" tag="div">
          <template #fairownPrivacyPolicy>
            <a
              :href="privacyPolicyFile"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{{ $t('FORM.CONSENTS.PRIVACY_POLICY') }}</span>
            </a>
          </template>
          <template #proaPrivacyPolicy>
            <a
              href="https://proastore.pl/pl/i/Polityka-prywatnosci/23"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{{ $t('FORM.CONSENTS.PROA_PRIVACY_POLICY') }}</span>
            </a>
          </template>
        </i18n>
      </div>

      <p class="text-small text-center margins__triple">
        {{ $t('FORM.CREDIT_CHECK_INFO') }}
      </p>

      <div class="text-center">
        <Button type="submit">{{ $t('CONTINUE') }}</Button>
      </div>

      <p class="text-small text-center">
        {{ $t('FORM.NO_COMMITMENT_WITH_APPLICATION') }}
      </p>
    </Margins>
  </form>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { SET_DELIVERY_INFO, SET_LOAN_APPLICATION_DATA } from '@/store/types';
import {
  Button,
  Check,
  Input,
  Margins,
  Separator,
  MobileInput,
} from '@/components';
import { constants } from '@/mixins';
import { DELIVERY_TYPES, STORE } from '@/constants';

export default {
  name: 'InbankLoanApplicationFormPL',
  mixins: [constants],
  components: {
    Input,
    Margins,
    Button,
    Check,
    Separator,
    MobileInput,
  },
  data() {
    return {
      formData: {
        country: STORE.PL.country.toUpperCase(),
        locale: 'pl-PL',
        customerData: {},
        customerContactData: {},
        purchase: {},
      },
      deliveryInfo: {
        city: '',
        zipCode: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
      },
      numberObject: {
        number: {
          input: '',
          international: '',
        },
      },
      proaTermsAndConditionsConsent: undefined,
    };
  },
  mounted() {
    this.formData = this.setLoanApplicationData(this.loanApplicationData);
    this.numberObject.number.international = this.loanApplicationData?.mobile;
  },
  methods: {
    ...mapMutations([SET_LOAN_APPLICATION_DATA, SET_DELIVERY_INFO]),
    setLoanApplicationData(object) {
      if (!object) {
        return this.formData;
      }
      this.formData.customerData.firstName = object.firstName;
      this.formData.customerData.lastName = object.surname;
      this.formData.customerContactData.email = object.email;
      this.formData.purchase.purchaseReference = object.referenceNumber;
      return this.formData;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.formData.language = this.language;

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      this.formData.customerContactData.mobile =
        this.numberObject.number.international.replace(/\s/g, '');

      const clonedFormData = JSON.parse(JSON.stringify(this.formData));
      clonedFormData.bundleId = this.bundle.id;
      clonedFormData.termsAndConditionsConsent = !!this.fairownConsent;
      clonedFormData.contractConsent = !!this.proaTermsAndConditionsConsent;

      const data = {
        ...clonedFormData,
        language: this.language,
      };

      const submitDeliveryInfo = {
        city: this.deliveryInfo.city,
        address:
          this.deliveryInfo.apartmentNumber !== ''
            ? `${this.deliveryInfo.street} ${this.deliveryInfo.houseNumber}-${this.deliveryInfo.apartmentNumber}`
            : `${this.deliveryInfo.street} ${this.deliveryInfo.houseNumber}`,
        zipCode: this.deliveryInfo.zipCode,
        deliveryType: DELIVERY_TYPES.HOME,
      };

      this.SET_LOAN_APPLICATION_DATA(data);

      this.SET_DELIVERY_INFO(submitDeliveryInfo);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.formData = { customerData: {}, customerContactData: {} };
      this.$validator.reset();
    },
    changeMobileNumber(numberObject) {
      const international =
        numberObject.number?.international || numberObject.number.input;
      this.numberObject = {
        ...this.numberObject,
        number: {
          ...this.numberObject.number,
          international,
        },
      };
    },
    populateNumberObject(numberObject) {
      if (!this.numberObject.input) {
        this.numberObject = numberObject;
      }
    },
    toggleSelectAll(toggled) {
      if (toggled) {
        this.formData.fairownConsent = true;
        this.proaTermsAndConditionsConsent = true;
      } else {
        this.formData.fairownConsent = undefined;
        this.proaTermsAndConditionsConsent = undefined;
      }
    },
    formatZipCode() {
      if (this.deliveryInfo.zipCode.slice(2, 3) === '-') {
        return;
      }

      if (this.deliveryInfo.zipCode.length > 2) {
        this.deliveryInfo.zipCode =
          this.deliveryInfo.zipCode.slice(0, 2) +
          '-' +
          this.deliveryInfo.zipCode.slice(2);
      }
    },
  },
  computed: {
    ...mapState(['loanApplicationData', 'language', 'backOffice']),
    ...mapGetters(['bundle']),
    fairownConsent() {
      const termsAndConditionsFileName = `TermsAndConditions-PL-${this.$i18n.locale.toUpperCase()}`;
      return `<p>${this.$t('FORM.CONSENTS.FAIROWN_CONSENT', [
        `<a href="${window.location.origin}/documents/${termsAndConditionsFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    proaTermsAndConditionsConsentText() {
      return `<p>${this.$t('FORM.CONSENTS.PROA_TERMS_AND_CONDITIONS_CONSENT', [
        `<a href="https://proastore.pl/pl/i/Regulamin-zakupow-sklepu-internetowego/22" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    privacyPolicyFile() {
      const policyFileName = `PrivacyPolicyPL-${this.$i18n.locale.toUpperCase()}`;
      return `${window.location.origin}/documents/${policyFileName}.pdf`;
    },
    isAuthenticated() {
      return Boolean(this.backOffice.authenticatedUser);
    },
  },
};
</script>
