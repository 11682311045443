<template>
  <component :is="tag" :class="[`margins-${size}`]">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Margins',
  props: {
    size: {
      validator: function (value) {
        return ['05x', '1x', '2x', '3x', '4x'].includes(value);
      },
      default: '1x',
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss">
.margins-05x > * + * {
  margin-top: 0.5rem;
}

.margins-1x > * + * {
  margin-top: 1rem;
}

.margins-2x > * + * {
  margin-top: 2rem;
}

.margins-3x > * + * {
  margin-top: 3rem;
}

.margins-4x > * + * {
  margin-top: 4rem;
}

.margins__one {
  margin-top: 1rem;
}

.margins__double {
  margin-top: 2rem;
}

.margins__triple {
  margin-top: 3rem;
}

.margins__mobile-sm {
  margin-top: 1rem;

  @include min-width(md) {
    margin-top: 0;
  }
}

.margins__mobile-md-double {
  margin-top: 2rem;

  @include min-width(md) {
    margin-top: 0;
  }
}
</style>
