<template>
  <Card color="grey" padding="small">
    <List :items="bulletsList" variant="bullets" class="text-small">
      <template #content="{ item }">
        <div v-if="item.href">
          <a
            class="text-black text-strong text-underline"
            :href="item.href"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ item.text }}
          </a>
        </div>
        <div v-else>
          {{ item.text }}
          <a
            v-if="item.extraLink"
            class="text-black text-strong text-underline"
            :href="item.extraLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ $t('YOUR_PLAN_BULLETS_LIST.HERE') }}
          </a>
        </div>
      </template>
    </List>
  </Card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { List, Card } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'YourPlanList',
  mixins: [constants],
  components: {
    List,
    Card,
  },
  computed: {
    ...mapGetters(['bundle']),
    ...mapState(['language']),
    bulletsList() {
      if (!this.bundle.totalPeriod) {
        return [];
      }

      const { upgradePeriod } = this.bundle;

      return [
        { text: this.$t('YOUR_PLAN_BULLETS_LIST.PL_ITEM_1') },
        { text: this.$t('YOUR_PLAN_BULLETS_LIST.PL_ITEM_2', [upgradePeriod]) },
        { text: this.$t('YOUR_PLAN_BULLETS_LIST.PL_ITEM_3') },
        {
          text: this.$t('YOUR_PLAN_BULLETS_LIST.PL_ITEM_4'),
          extraLink: 'https://www.lg.com/pl/promocje/subflex-3lata',
        },
        {
          text: this.$t('YOUR_PLAN_BULLETS_LIST.READ_MORE'),
          href: 'https://www.lg.com/pl/subflex',
        },
      ];
    },
  },
};
</script>
