var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:_vm.modalRef,attrs:{"id":"updateSerialAndMerchantReferenceModal","title":"Update serial number and merchant reference modal","size":"lg","hide-footer":"","hide-header":""}},[_c('button',{staticClass:"modal__close",attrs:{"type":"button"},on:{"click":_vm.hideModal}},[_c('CloseSVG',{staticClass:"icon"})],1),_c('Margins',[_c('div',{staticClass:"align-items-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('ORDER_ENTER_SERIAL')))])]),_c('div',{staticClass:"margins__double"},[_c('Input',{staticClass:"margins__mobile-md-double",attrs:{"label":_vm.$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER'),"name":"referenceNumber","readonly":true},model:{value:(_vm.serialInfoDTO.referenceNumber),callback:function ($$v) {_vm.$set(_vm.serialInfoDTO, "referenceNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"serialInfoDTO.referenceNumber"}})],1),_c('div',{staticClass:"margins__double"},[_c('Input',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          alpha_num: true,
          min: 10,
          max: 12,
          regex: _vm.REGEX.alphaNumEN,
        }),expression:"{\n          required: true,\n          alpha_num: true,\n          min: 10,\n          max: 12,\n          regex: REGEX.alphaNumEN,\n        }"}],staticClass:"margins__mobile-md-double",attrs:{"label":`${_vm.$t('BACK_OFFICE_ORDERS.SERIAL')} *`,"name":"serial","error":_vm.errors.first('serial'),"data-vv-as":_vm.$t('BACK_OFFICE_ORDERS.SERIAL')},model:{value:(_vm.serialInfoDTO.serial),callback:function ($$v) {_vm.$set(_vm.serialInfoDTO, "serial", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"serialInfoDTO.serial"}})],1),_c('div',{staticClass:"margins__double"},[_c('Input',{staticClass:"margins__mobile-md-double",attrs:{"label":_vm.$t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER'),"name":"merchantReference","data-vv-as":_vm.$t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER')},model:{value:(_vm.serialInfoDTO.merchantReference),callback:function ($$v) {_vm.$set(_vm.serialInfoDTO, "merchantReference", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"serialInfoDTO.merchantReference"}})],1),_c('div',{staticClass:"text-center margins__double"},[_c('Button',{attrs:{"loading":_vm.isLoading,"type":"submit","title":_vm.$t('SAVE')},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t('SAVE'))+" ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }