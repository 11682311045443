<template>
  <div>
    <div class="table-responsive">
      <table class="table table--no-wrap table--narrow">
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              {{ $t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER') }}
            </th>
            <th class="text-left text-wrap">
              {{ $t('BACK_OFFICE_ORDERS.BUNDLE_PRODUCT_CODE') }}
            </th>
            <th class="text-left">
              {{ $t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER') }}
            </th>
            <th class="text-left">
              {{ $t('BACK_OFFICE_ORDERS.CREATED') }}
            </th>
            <th class="text-left">
              {{ $t('BACK_OFFICE_ORDERS.CREATED_BY') }}
            </th>
            <th>{{ $t('BACK_OFFICE_ORDERS.STATUS') }}</th>
            <th class="text-right">
              {{ $t('BACK_OFFICE_ORDERS.ACTIONS') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="bundleOrders.length === 0">
          <tr>
            <td colspan="8">{{ $t('ORDERS_NO_RESULTS') }}</td>
          </tr>
        </tbody>
        <tbody v-else v-for="order in bundleOrders" :key="order.createdDtime">
          <tr
            :class="[
              {
                ['has-highlight']:
                  +order.referenceNumber === +$route.query.referenceNumber,
                ['disabled']: refreshingOrder === order.referenceNumber,
              },
            ]"
          >
            <td class="text-center">
              <Button
                v-if="displayOrderDetails[order.referenceNumber]"
                variant="ghost"
                width="auto"
                @click="closeOrderDetails(order.referenceNumber)"
                :title="$t('CLOSE_DETAILS')"
              >
                <CloseDetailsSVG class="icon" />
              </Button>
              <Button
                v-else
                variant="ghost"
                width="auto"
                @click="getOrderDetails(order.referenceNumber)"
                :title="$t('VIEW_DETAILS')"
              >
                <ViewDetailsSVG class="icon" />
              </Button>
            </td>
            <td class="text-wrap">
              <strong>{{ order.referenceNumber }}</strong>
            </td>
            <td>
              <strong>{{ order.bundleProductCode }}</strong>
            </td>
            <td class="text-wrap">
              <strong>{{ order.merchantReference }}</strong>
            </td>
            <td>
              {{
                new Intl.DateTimeFormat('pl', {
                  dateStyle: 'short',
                  timeStyle: 'short',
                }).format(new Date(order.createdDtime))
              }}
            </td>
            <td>
              {{ order.createdBy }}
            </td>
            <td class="text-center">
              <Badge :variant="orderStatusVariant(order.status)">{{
                $t(`ORDER_STATUSES.${order.status}`)
              }}</Badge>
            </td>
            <td class="text-right">
              <Button
                v-if="isHandedOver(order.status)"
                variant="ghost"
                width="auto"
                :title="$t('EDIT')"
                @click="
                  showUpdateSerialAndMerchantReferenceModal(
                    order.referenceNumber,
                    order.serial,
                    order.merchantReference,
                  )
                "
              >
                <EditSVG />
              </Button>
              <Button
                variant="ghost"
                width="auto"
                :disabled="!!refreshingOrder"
                @click="refreshStatus(order.referenceNumber)"
                :title="$t('REFRESH')"
              >
                <ProcessingSVG class="icon" />
              </Button>
              <Button
                v-if="canContinueThroughLink(order)"
                :href="order.continueLink"
                target="_blank"
                variant="ghost"
                width="auto"
                :title="$t('OPEN_ORDER_LINK')"
              >
                <ExternalLinkSVG class="icon" />
              </Button>
              <Button
                v-if="canHandOver(order.status)"
                variant="ghost"
                width="auto"
                @click="
                  showHandOverModal(
                    order.referenceNumber,
                    order.serial,
                    order.merchantReference,
                  )
                "
                :title="$t('HAND_OVER')"
              >
                <CheckSVG class="icon" />
              </Button>
              <Button
                v-if="canAnnul(order)"
                variant="ghost"
                width="auto"
                @click="showAnnulModal(order)"
                :title="$t('ANNUL')"
              >
                <DeleteSVG class="icon" />
              </Button>
            </td>
          </tr>
          <tr v-if="displayOrderDetails[order.referenceNumber]">
            <td class="no-padding" colspan="8">
              <BackOfficeBundleOrderDetails
                :orderDetails="bundleOrderDetails[order.referenceNumber]"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ApplicationHandOverModal
      :ref="handOverModalRef"
      @handedOver="handleHandedOver"
    />
    <UpdateSerialAndMerchantReferenceModal
      :ref="updateSerialNumberAndMerchantReferenceModalRef"
      @update-serial-and-merchant-reference="updateSerialAndMerchantReference"
    />
    <ApplicationAnnulModal :ref="annulModalRef" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  GET_BUNDLE_ORDER_DETAILS,
  REFRESH_APPLICATION_STATUS,
  UPDATE_SERIAL_AND_MERCHANT_REFERENCE,
} from '@/store/types';
import { Badge, Button } from '@/components';
import { constants } from '@/mixins';
import BackOfficeBundleOrderDetails from './BackOfficeBundleOrderDetails.vue';
import CheckSVG from '@/assets/images/check-solid.svg';
import ProcessingSVG from '@/assets/images/processing.svg';
import ExternalLinkSVG from '@/assets/images/external-link.svg';
import ViewDetailsSVG from '@/assets/images/eye-solid.svg';
import CloseDetailsSVG from '@/assets/images/eye-slash-solid.svg';
import DeleteSVG from '@/assets/images/times-circle-solid.svg';
import EditSVG from '@/assets/images/pencil-square.svg';
import ApplicationHandOverModal from '@/containers/BackOffice/ApplicationHandOverModal';
import ApplicationAnnulModal from '@/containers/BackOffice/ApplicationAnnulModal';
import { orderStatusVariant } from '@/utils';
import UpdateSerialAndMerchantReferenceModal from '@/containers/BackOffice/UpdateSerialAndMerchantReferenceModal.vue';

export default {
  name: 'BundleOrdersTable',
  mixins: [constants],
  components: {
    UpdateSerialAndMerchantReferenceModal,
    DeleteSVG,
    CheckSVG,
    ProcessingSVG,
    ViewDetailsSVG,
    CloseDetailsSVG,
    ExternalLinkSVG,
    EditSVG,
    Badge,
    Button,
    ApplicationAnnulModal,
    ApplicationHandOverModal,
    BackOfficeBundleOrderDetails,
  },
  data() {
    return {
      refreshingOrder: null,
      displayOrderDetails: {},
      bundleOrderDetails: {},
    };
  },
  methods: {
    ...mapActions([
      GET_BUNDLE_ORDER_DETAILS,
      REFRESH_APPLICATION_STATUS,
      UPDATE_SERIAL_AND_MERCHANT_REFERENCE,
    ]),
    canHandOver(status) {
      return (
        status === this.ORDER_STATUSES.COMPLETED ||
        status === this.ORDER_STATUSES.GRANTED
      );
    },
    isHandedOver(status) {
      return status === this.ORDER_STATUSES.HANDED_OVER;
    },
    canAnnul(order) {
      const inbankAnnulableStatuses = [this.ORDER_STATUSES.GRANTED];
      const canAnnulInbank =
        order.inbank === true && inbankAnnulableStatuses.includes(order.status);

      return canAnnulInbank;
    },
    canContinueThroughLink(order) {
      const continueStatuses = [
        this.ORDER_STATUSES.PENDING,
        this.ORDER_STATUSES.SUBMITTED,
        this.ORDER_STATUSES.CREATED,
      ];

      return continueStatuses.includes(order.status) && order.continueLink;
    },
    closeOrderDetails(referenceNumber) {
      this.$set(this.displayOrderDetails, referenceNumber, false);
    },
    async getOrderDetails(referenceNumber) {
      if (!this.bundleOrderDetails[referenceNumber]) {
        await this.updateOrderDetails(referenceNumber);
      }

      this.$set(this.displayOrderDetails, referenceNumber, true);
    },
    async updateOrderDetails(referenceNumber) {
      this.$set(
        this.bundleOrderDetails,
        referenceNumber,
        await this.GET_BUNDLE_ORDER_DETAILS(referenceNumber),
      );
    },
    handleHandedOver(referenceNumber, serial, merchantReference) {
      if (this.displayOrderDetails[referenceNumber]) {
        this.updateOrderDetails(referenceNumber, serial, merchantReference);
      }
    },
    showHandOverModal(referenceNumber, serial, merchantReference) {
      this.$refs[this.handOverModalRef].showModal(
        referenceNumber,
        serial,
        merchantReference,
      );
    },
    showUpdateSerialAndMerchantReferenceModal(
      referenceNumber,
      serial,
      merchantReference,
    ) {
      this.$refs[this.updateSerialNumberAndMerchantReferenceModalRef].showModal(
        referenceNumber,
        serial,
        merchantReference,
      );
    },
    showAnnulModal(order) {
      this.$refs[this.annulModalRef].showModal(order);
    },
    async refreshStatus(referenceNumber) {
      this.refreshingOrder = referenceNumber;
      await this.REFRESH_APPLICATION_STATUS(referenceNumber);

      if (this.displayOrderDetails[referenceNumber]) {
        await this.updateOrderDetails(referenceNumber);
      }

      this.refreshingOrder = null;
    },
    async updateSerialAndMerchantReference(serialInfoDTO) {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }
      await this.UPDATE_SERIAL_AND_MERCHANT_REFERENCE(serialInfoDTO);
    },
    orderStatusVariant(status) {
      return orderStatusVariant(status);
    },
  },
  computed: {
    ...mapState(['backOffice']),
    bundleOrders() {
      return this.backOffice.bundleOrders.content;
    },
    handOverModalRef() {
      return `hand-over-modal`;
    },
    updateSerialNumberAndMerchantReferenceModalRef() {
      return `update-serial-and-merchant-reference-modal`;
    },
    annulModalRef() {
      return `annul-modal`;
    },
  },
};
</script>
