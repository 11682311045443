<template>
  <div class="maintenance">
    <Container>
      <Card padding="large">
        <Margins>
          <img alt="SmartDeal Logo" src="@/assets/images/smart-deal-logo.png" />
          <Separator size="large" />
          <h2 class="text-center">{{ $t('COMING_SOON') }}</h2>
          <Separator size="large" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { Card, Container, Margins, Separator } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'Maintenance',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Separator,
  },
};
</script>

<style lang="scss">
.maintenance {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 9rem);
}
</style>
