<template>
  <Modal v-model="value" @change="onChange">
    <template #header="{ close }">
      <button type="button" class="modal__close" @click="close">
        <CloseSVG class="icon" />
      </button>
    </template>
    <template #default>
      <div v-if="isLoading" class="text-center">
        <Loader class="margins__double" :text="$t('LOADING')" />
      </div>
      <div v-else>
        <div class="text-center margins__triple">
          {{ $t('SEND_INVITATION_INFO') }}
        </div>
      </div>
    </template>
    <template #footer="{ cancel }">
      <ButtonGroup margin="small" class="text-center margins__double">
        <Button variant="inverse" @click="cancel">
          {{ $t('NO') }}
        </Button>
        <Button @click="sendInvitations">
          {{ $t('YES') }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>

<script>
import Button from '@/components/Button.vue';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { SEND_INVITATIONS } from '@/store/types';
import { mapActions, mapState } from 'vuex';
import ButtonGroup from '@/components/ButtonGroup.vue';
import Modal from '@/components/Modal.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'InvitationModal',
  components: { ButtonGroup, Button, CloseSVG, Modal, Loader },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions([SEND_INVITATIONS]),
    async sendInvitations() {
      await this.SEND_INVITATIONS();
      this.onChange(event);
    },
    onChange(event) {
      this.$emit('change', event);
    },
  },
  computed: {
    ...mapState(['isLoading']),
  },
};
</script>
