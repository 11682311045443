<template>
  <div class="back-office-bundle-orders">
    <Margins>
      <Container>
        <Margins size="2x">
          <BundleOrdersFilter :paginateTo="paginateTo" />

          <PaginationButtons
            :nav="backOffice.bundleOrders.nav"
            @paginate="handlePagination"
          />
        </Margins>
      </Container>

      <Container size="large">
        <b-row v-if="isLoading" align-h="center">
          <b-col cols="auto">
            <Loader :text="$t('LOADING')" />
          </b-col>
        </b-row>

        <BundleOrdersTable v-else />
      </Container>

      <Container>
        <PaginationButtons
          :nav="backOffice.bundleOrders.nav"
          @paginate="handlePagination"
        />
      </Container>
    </Margins>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Loader, Margins, Container } from '@/components';
import { constants } from '@/mixins';
import PaginationButtons from './PaginationButtons.vue';
import BundleOrdersTable from './BundleOrdersTable.vue';
import BundleOrdersFilter from './BundleOrdersFilter.vue';

export default {
  name: 'BackOfficeBundleOrders',
  mixins: [constants],
  components: {
    Loader,
    Margins,
    Container,
    PaginationButtons,
    BundleOrdersTable,
    BundleOrdersFilter,
  },
  data() {
    return {
      paginateTo: null,
    };
  },
  methods: {
    handlePagination(pageTo) {
      this.paginateTo = pageTo;
    },
  },
  computed: {
    ...mapState(['isLoading', 'backOffice']),
  },
};
</script>
