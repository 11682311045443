<template>
  <Modal v-model="value" @change="onChange" hide-footer>
    <template #header="{ close }">
      <Button variant="ghost" class="modal__close" @click="close">
        <CloseSVG class="icon" />
      </Button>
    </template>
    <template #default>
      <div class="my-3 px-3">
        <div
          v-for="(status, index) in ORDER_STATUSES"
          :key="index"
          class="mt-2"
        >
          <p>
            <span class="text-strong">
              {{ $t(`ORDER_STATUSES.${status}`) + ': ' }}
            </span>
            {{ $t(`STATUS_DEFINITION.${status}`) }}
          </p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import { constants } from '@/mixins';

export default {
  name: 'StatusInformationModal',
  mixins: [constants],
  components: { Modal, CloseSVG, Button },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event);
    },
  },
};
</script>
