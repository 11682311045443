<template>
  <div :class="[{ [`button-group-margin--${margin}`]: !!margin }]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
  props: {
    margin: {
      validator: function (value) {
        return ['small'].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
.button-group-margin--small > * {
  margin-left: 1rem;
}
</style>
