import { REGEX } from '@/constants';

export {
  validatePersonalCodePL,
  validateInbankPhonePL,
  isUsernameCompanyEmail,
};

function validatePersonalCodePL(idCode) {
  // Regex the format 12345678901
  const regexResult = /^[0-9]{11}$/.test(idCode);
  if (!regexResult) {
    return false;
  }
  const multiplier = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let checkSum = 0;

  //muliply idCode digits by checkSum digits
  for (let i = 0; i < 10; i++) {
    checkSum += multiplier[i] * idCode.charAt(i);
  }

  checkSum = checkSum % 10 === 0 ? 0 : 10 - (checkSum % 10);
  return checkSum === parseInt(idCode.charAt(10));
}

function extractPhoneNumber(phoneObjectOrNumber) {
  if (!phoneObjectOrNumber.number) {
    return phoneObjectOrNumber;
  } else {
    return phoneObjectOrNumber.number.international;
  }
}

function extractPhoneNumberWithoutSpace(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumber(phoneObjectOrNumber);
  return phoneNumber?.replace(/\s/g, '');
}

function validateInbankPhonePL(phoneObjectOrNumber) {
  const phoneNumber = extractPhoneNumberWithoutSpace(phoneObjectOrNumber);
  return REGEX.INBANK.PL.mobile.test(phoneNumber);
}

/**
 * Validate a date
 *
 * @param {Number} year The full year in 4 digits
 * @param {Number} month The month number
 * @param {Number} day The day number
 * @param {Boolean} [notInFuture] If true, the date must not be in the future
 * @returns {Boolean}
 */
function birthDate(year, month, day, notInFuture) {
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return false;
  }
  if (day.length > 2 || month.length > 2 || year.length > 4) {
    return false;
  }

  day = parseInt(day, 10);
  month = parseInt(month, 10);
  year = parseInt(year, 10);

  if (year < 1900 || year > 9999 || month <= 0 || month > 12) {
    return false;
  }
  var numDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  // Update the number of days in Feb of leap year
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    numDays[1] = 29;
  }

  // Check the day
  if (day <= 0 || day > numDays[month - 1]) {
    return false;
  }

  if (notInFuture === true) {
    var currentDate = new Date(),
      currentYear = currentDate.getFullYear(),
      currentMonth = currentDate.getMonth(),
      currentDay = currentDate.getDate();
    return (
      year < currentYear ||
      (year === currentYear && month - 1 < currentMonth) ||
      (year === currentYear && month - 1 === currentMonth && day < currentDay)
    );
  }

  return true;
}

function isUsernameCompanyEmail(username) {
  return REGEX.companyEmailPattern.test(username);
}
