var render = function render(){var _vm=this,_c=_vm._self._c;return _c('hr',{class:[
    'separator',
    {
      'separator--line': _vm.hasLine,
      [`separator--${_vm.size}`]: !!_vm.size,
    },
  ]})
}
var staticRenderFns = []

export { render, staticRenderFns }