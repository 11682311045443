<template>
  <b-modal
    :ref="modalRef"
    title="Hand over modal"
    size="lg"
    hide-footer
    hide-header
  >
    <button type="button" class="modal__close" @click="hideModal">
      <CloseSVG class="icon" />
    </button>
    <Margins>
      <div class="align-items-center">
        <h4>{{ $t('ORDER_ENTER_SERIAL') }}</h4>
      </div>

      <div class="margins__double">
        <Input
          :label="$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER')"
          name="referenceNumber"
          v-model.trim="serialInfoDTO.referenceNumber"
          :readonly="true"
          class="margins__mobile-md-double"
        />
      </div>

      <div class="margins__double">
        <Input
          :label="`${$t('BACK_OFFICE_ORDERS.SERIAL')}`"
          name="serial"
          v-model.trim="serialInfoDTO.serial"
          :error="errors.first('serial')"
          v-validate="{
            alpha_num: true,
            min: 10,
            max: 12,
            regex: REGEX.alphaNumEN,
          }"
          class="margins__mobile-md-double"
          :data-vv-as="$t('BACK_OFFICE_ORDERS.SERIAL')"
        />
      </div>

      <div class="margins__double">
        <Input
          :label="$t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER')"
          name="merchantReference"
          v-model.trim="serialInfoDTO.merchantReference"
          class="margins__mobile-md-double"
          :data-vv-as="$t('BACK_OFFICE_ORDERS.MERCHANT_REF_NUMBER')"
        />
      </div>

      <div class="text-center margins__double">
        <Button
          :loading="isLoading"
          type="submit"
          @click="handleHandOver()"
          :title="$t('HAND_OVER')"
          >{{ $t('HAND_OVER') }}
        </Button>
      </div>
    </Margins>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { SET_ORDER_HANDED_OVER } from '@/store/types';
import { Button, Margins, Input } from '@/components';
import { constants } from '@/mixins';
import CloseSVG from '@/assets/images/times-circle-regular.svg';

export default {
  name: 'ApplicationHandOverModal',
  mixins: [constants],
  components: {
    Margins,
    CloseSVG,
    Input,
    Button,
  },
  data() {
    return {
      serialInfoDTO: {
        referenceNumber: null,
        serial: null,
        merchantReference: null,
      },
    };
  },
  methods: {
    ...mapActions([SET_ORDER_HANDED_OVER]),
    async handleHandOver() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      await this.SET_ORDER_HANDED_OVER(this.serialInfoDTO);
      this.$emit('handedOver', this.serialInfoDTO);
      this.hideModal();
    },
    showModal(referenceNumber, serial, merchantReference) {
      if (this.serialInfoDTO.referenceNumber !== referenceNumber) {
        this.serialInfoDTO.serial = serial;
        this.serialInfoDTO.merchantReference = merchantReference;
        this.serialInfoDTO.referenceNumber = referenceNumber;
      }
      this.$refs[this.modalRef].show();
    },
    hideModal() {
      this.$refs[this.modalRef].hide();
    },
  },
  computed: {
    ...mapState(['isLoading']),
    modalRef() {
      return `hand-over-modal`;
    },
  },
};
</script>
