<template>
  <div class="remove-bundle-card">
    <img
      :data-src="`${API_BASE_URL}/asset-picture/${asset.id}/product`"
      class="lazyload"
      :alt="asset.name"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { constants } from '@/mixins';

export default {
  name: 'ApplyAssetCard',
  mixins: [constants],
  computed: {
    ...mapGetters(['asset']),
  },
};
</script>
