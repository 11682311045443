// This file is quite pointless without custom VeeValidate.extend methods
// but it is here for the setup for future changes
import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesPL from 'vee-validate/dist/locale/pl';

import { Validator } from 'vee-validate';
import {
  validatePersonalCodePL,
  validateInbankPhonePL,
  isUsernameCompanyEmail,
} from './utils/validation.js';

export const dictionary = {
  en: {
    ...validationMessagesEN,
    messages: {
      ...validationMessagesEN.messages,
      length: (_, length) => `The ${_} length must be ${length[0]}`,
    },
    custom: {
      username: {
        companyEmail: () =>
          'The e-mail entered is not a company e-mail address',
      },
    },
  },
  pl: {
    ...validationMessagesPL,
    messages: {
      ...validationMessagesPL.messages,
      length: (_, length) => `The ${_} length must be ${length[0]}`,
    },
    custom: {
      username: {
        companyEmail: () => 'Podany e-mail nie jest firmowym adresem e-mail',
      },
    },
  },
};

Validator.extend('personalCodePL', (personalCode) =>
  validatePersonalCodePL(personalCode),
);

Validator.extend('inbankPhonePL', (phoneObjectOrNumber) =>
  validateInbankPhonePL(phoneObjectOrNumber),
);

Validator.extend('companyEmail', (username) =>
  isUsernameCompanyEmail(username),
);
