import { API_BASE_URL, ROUTES } from '@/constants';
import qs from 'query-string';
import store from '@/store';
import { BACK_OFFICE_LOGOUT } from '@/store/types';

export const makeFetch = async (url, additionalOptions) => {
  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const options = {
    ...defaultOptions,
    ...additionalOptions,
  };

  const response = await fetch(`${API_BASE_URL}${url}`, options);

  const body = await getBody(response);

  if (!response.ok) {
    let error = new Error(body.message);
    error.status = body.status;
    error.code = body.errorCode;
    error.statusCode = response.status;

    console.error('error', error);

    if (error.status === 401 && url !== ROUTES.BACK_OFFICE_LOGIN.path) {
      store.dispatch(BACK_OFFICE_LOGOUT);
    }
    throw error;
  }

  return body;
};

const getBody = async (response) => {
  try {
    return await response.json();
  } catch (error) {
    return await response;
  }
};

class ApiService {
  static isStore() {
    return makeFetch(`/host/is-store`);
  }

  static getBundles() {
    return makeFetch(`/bundle/find-all`);
  }

  static getBundleOrderDetails(referenceNumber) {
    return makeFetch(`/bundle-order/${referenceNumber}/details`);
  }

  static getBundleOrderForIDeal(referenceNumber) {
    return makeFetch(`/bundle-order/get-ideal/${referenceNumber}`);
  }

  static submitInbankLoanApplication(data) {
    return makeFetch(`/application/submit`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static getApplicationStatus(referenceNumber) {
    return makeFetch(`/application/status/${referenceNumber}`);
  }

  static getApplicationStatusUpdate(referenceNumber) {
    return makeFetch(`/application/status/${referenceNumber}/update`, {
      method: 'PATCH',
    });
  }

  static createSigningSession(data) {
    return makeFetch(`/application/create-signing-session`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static finalizeAccountRegistration(data) {
    return makeFetch(`/application/finalize-account-registration`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  static getSignatureStatus(signingReference) {
    return makeFetch(`/application/get-signature-status/${signingReference}`);
  }

  static isBundleOrderComplete(data) {
    return makeFetch(`/bundle-order/complete`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static askQuestion(data) {
    return makeFetch(`/info/ask-question`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static getBundleOrders(params) {
    const queryParams = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [key, value || '']),
    );
    const query = new URLSearchParams(queryParams);

    return makeFetch(`/bundle-order/find-all?${query.toString()}`);
  }

  static getOrderCreators() {
    return makeFetch(`/bundle-order/creators`);
  }

  static handOver(data) {
    return makeFetch(`/bundle-order/hand-over`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  static updateSerialAndMerchantReference(data) {
    return makeFetch(
      `/bundle-order/${data.referenceNumber}/update-serial-and-merchant-reference`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
      },
    );
  }

  static login(data) {
    return makeFetch('/login', {
      body: qs.stringify(data),
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  static logout() {
    return makeFetch('/logout');
  }

  static alive() {
    return makeFetch('/alive');
  }

  static acceptInvitation(invitationId, data) {
    return makeFetch(`/invitation/${invitationId}/accept`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static getInvitation(invitationId) {
    return makeFetch(`/invitation/${invitationId}/validate`);
  }

  static sendInvitations() {
    return makeFetch('/invitation/send?storeCode=lg', {
      method: 'POST',
    });
  }

  static getStoreFromShortName(storeName) {
    return makeFetch(`/store/short/?store=${storeName}`);
  }

  static getBundleFromCountryCodeAndProductCode(countryCode, productCode) {
    return makeFetch(
      `/bundle/find-by-code/${countryCode}?product=${productCode}`,
    );
  }

  static getPickupStores() {
    return makeFetch(`/store/find-all-pickup-stores/`);
  }

  static getOmnivaMachines() {
    return makeFetch(`/bundle-delivery/omniva-machine-locations`);
  }

  static submitDeliveryInfo(data) {
    return makeFetch(`/bundle-delivery/submit`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static getContinueLink(applicationReference) {
    return makeFetch(`/application/continue-link/${applicationReference}`);
  }

  static annulOrder(applicationReference) {
    return makeFetch(`/application/annul/${applicationReference}`, {
      method: 'PATCH',
    });
  }

  static requestPasswordReset(data) {
    return makeFetch(`/password-reset/request/`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static resetPassword(tokenId, data) {
    return makeFetch(`/password-reset/${tokenId}/reset`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static resetPasswordCheck(tokenId) {
    return makeFetch(`/password-reset/${tokenId}`);
  }

  static continueAsCustomer(bundleId) {
    return makeFetch(`/bundle-order/create-for-bundle/${bundleId}`, {
      method: 'POST',
    });
  }

  static getBundlesPaginatedResponse(request) {
    return makeFetch(`/bundle?seller=LG`, {
      method: 'POST',
      body: JSON.stringify(request),
    });
  }

  static fetchProductPictureAsBlob(assetId) {
    return fetch(`${API_BASE_URL}/asset-picture/${assetId}/product`).then(
      (res) => res.blob(),
    );
  }
  static doesBundleExist(data) {
    return makeFetch(
      `/bundle/exists?product=${data.bundle.productCode}&seller=${data.bundle.seller}`,
    );
  }

  static deactivateBundles(bundleIds) {
    return makeFetch(`/bundle/deactivate`, {
      body: JSON.stringify(bundleIds),
      method: 'PATCH',
    });
  }

  static editBundle(data) {
    return makeFetch(`/bundle/edit`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static createBundle(data) {
    return makeFetch(`/bundle/create`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static continueAsCustomerWithSMS(data) {
    return makeFetch(`/bundle-order/send-sms-for-bundle/${data.bundleId}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static retryApiRequest(retryFunction) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const retryResponse = await retryFunction();
        resolve(retryResponse);
      }, 5000);
    });
  }
}

export default ApiService;
